import { Component } from '@angular/core';

@Component({
  selector: 'app-nothing',
  templateUrl: './nothing.component.html',
  styleUrls: ['./nothing.component.css']
})
export class NothingComponent {

}
