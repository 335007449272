import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { APP_PRODUCT_DETAILS_SHOWN, AppModules, CURRENT_APP_MODULE, SELECTED_CUBE, SELECTED_OUTPUT } from '../../reducers';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '../../authentication.service';
import { Cube, Output } from 'src/app/model/cube';
import { ActivatedRoute } from '@angular/router';
import { HideProductDetails } from 'src/app/actions/actions';
import { Product } from 'src/app/model/product';
import { Jingle } from 'src/app/model/jingle';


@Component({
  selector: 'app-player-header',
  templateUrl: './player-header.component.html',
  styleUrls: ['./player-header.component.scss']
})
export class PlayerHeaderComponent implements OnInit, OnDestroy{
  ios = false;

  currentModule = AppModules.home;

  home = AppModules.home;

  cubecontrol = AppModules.cubecontrol;

  localPlayback = AppModules.localPlayback;

  private  subscriptions = new Subscription();

  public cube$: Observable<Cube>;

  selectedOutput$: Observable<Output>;

  public productDetailsShown: Product;

  @ViewChild('logoutWidget') logoutWidget;
  s

  constructor(
    private platform: Platform,
    private store: Store<any>,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ){
    this.ios = this.platform.is('ios');
  }

  ngOnInit(){
    this.subscriptions.add(this.store.select(CURRENT_APP_MODULE)
      .subscribe(module => this.currentModule = module)
    );
    this.subscriptions.add(this.store.select(APP_PRODUCT_DETAILS_SHOWN)
      .subscribe(product => this.productDetailsShown = product)
    );
    this.cube$ = this.store.select(SELECTED_CUBE);
    this.selectedOutput$ = this.store.select(SELECTED_OUTPUT);
  }

  closeProduct(){
    this.store.dispatch(HideProductDetails()); 
  }

  logout(){
    this.logoutWidget.fire();
  }

  async doLogout(){
    await this.authenticationService.logout();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
