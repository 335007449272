import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) {
  }

  likeSong(stationId: string, songId: string, comment: string) {
    return this.http.post(`playlists/stations/${stationId}/songs/${songId}/like`, { comment });
  }

  dislikeSong(stationId: string, songId: string, comment: string) {
    return this.http.post(`playlists/stations/${stationId}/songs/${songId}/dislike`, { comment });
  }

  generalFeedback(comment: string) {
    return this.http.post('feedback', { comment });
  }

}
