import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Cube } from './model/cube';
import { CubesStatusUpdated } from './actions/actions';
import { LocalPlayableSong } from './model/local-playback';

@Injectable({
  providedIn: 'root'
})
export class LocalPlaybackService {
  constructor(
    private http: HttpClient
  ) {
  }


  loadPlaylist(stationId: string) {
    return this.http.get<LocalPlayableSong[]>(`/playlists/stations/${stationId}`);
  }
  loadPlaylistWithLastSong(stationId: string, lastSongId: string, lastSongStartTime: string) {
    return this.http.get<LocalPlayableSong[]>(`/playlists/stations/${stationId}?songId=${lastSongId}&startTime=${lastSongStartTime}&songLimit=100`);
  }

}
