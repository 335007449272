import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {

  constructor(
    @Inject('BASE_API_URL') private baseUrl: string,
    private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoginCall = (request.url.indexOf('login') >= 0);
    const cookie$ = isLoginCall ? of('') : this.authenticationService.getCookie();


    return cookie$
      .pipe(
        flatMap(cookie => {
          const headers = request.headers.append('auth', cookie);

          const apiReq = request.clone({ url: `${this.baseUrl}/${request.url}`, headers: headers });
          return next.handle(apiReq);
        })
      );

  }
}
