import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CubesStatusUpdated } from './actions/actions';
import { Cube, PlaybackSettings, StationSettings, VolumeSettings } from './model/cube';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription, timer } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CubeService {

  private poller: Observable<any>;

  private subscription: Subscription;

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private platform: Platform
  ) {
    this.platform.pause.subscribe(async () => {
      this.stopPollingStatus();
    });
    this.platform.resume.subscribe(async () => {
      this.startPollingStatus();
    });

  }


  startPollingStatus() {
    if (!this.poller) {
      this.poller = timer(0, 5000).pipe(
        switchMap(() => this.http.get<Cube[]>('cubes')
          .pipe(
            map(response => {
              this.store.dispatch(CubesStatusUpdated({ cubes: response }));
              return 'poller success';
            }),
            catchError(err => {
              console.log('Poller Error:', err);
              return of('poller error');
            })
          ))
      );
      this.subscription = this.poller.subscribe();
    }

  }

  stopPollingStatus() {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
      this.poller = undefined;
    }
  }

  setPlayback(cubeId: string, outputIndex: number, playbackSettings: PlaybackSettings) {
    return this.http.post<Cube[]>(`cubes/playback/${cubeId}/outputs/${outputIndex}`, playbackSettings).pipe(
      tap(response => {
        this.store.dispatch(CubesStatusUpdated({ cubes: response }));
      })).subscribe(
      (value) => console.log('Playback Response: ' + value),
      (error => console.log('Error setting cube playback', error)),
      () => console.log('Playback call completed')
    );
  }

  setVolume(cubeId: string, outputIndex: number, volumeSettings: VolumeSettings) {
    return this.http.put<Cube[]>(`cubes/playback/${cubeId}/outputs/${outputIndex}/volume`, volumeSettings).pipe(
      tap(response => {
        this.store.dispatch(CubesStatusUpdated({ cubes: response }));
      })).subscribe(
      (value) => console.log('Set Volume Response: ' + value),
      (error => console.log('Error setting cube volume', error)),
      () => console.log('Volume call completed')
    );
  }

  setStation(cubeId: string, outputIndex: number, stationSettings: StationSettings) {
    return this.http.put<Cube[]>(`cubes/playback/${cubeId}/outputs/${outputIndex}/station`, stationSettings).pipe(
      tap(response => {
        this.store.dispatch(CubesStatusUpdated({ cubes: response }));
      })).subscribe(
      (value) => console.log('Set Volume Response: ' + value),
      (error => console.log('Error setting cube volume', error)),
      () => console.log('Volume call completed')
    );
  }

  playJingle(cubeId: string, outputIndex: number, jingleId: string) {
    return this.http.post<Cube[]>(`cubes/jingle/${cubeId}/outputs/${outputIndex}/jingle/${jingleId}`, null).pipe(
      tap(response => {
        this.store.dispatch(CubesStatusUpdated({ cubes: response }));
      })).subscribe(
      (value) => console.log('Jingle Response: ' + value),
      (error => console.log('Error setting cube Jingle', error)),
      () => console.log('Jingle call completed')
    );
  }
}
