import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loginStateChanged, logoutAction,
  PauseLocally,
  PlaybackEndedLocally,
  PlayingLocally, PlayJingleLocally,
  PlaylistLoaded,
  PlaylistReloadedLoaded,
  PlayLocally,
  PlayNextSongLocally,
  TogglePlayLocally
} from './actions/actions';
import { concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { LocalPlaybackService } from './local-playback.service';
import { Store } from '@ngrx/store';
import { AudioService } from './listen-on-device/audio.service';
import { LOCALPLAYINGPRODUCT, LOCALPLAYLIST, LOCALSHOULDPLAY } from './reducers';


@Injectable()
export class LocalPlaybackEffects {

  loadPlaylist$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PlayLocally),
        concatMap(action => this.localPlaybackService.loadPlaylist(action.stationId)),
        map(playlist => PlaylistLoaded({ playlist }))
      );
    }, { dispatch: true }
  );

  toggleMusic$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(TogglePlayLocally),
        withLatestFrom(this.store.select(LOCALSHOULDPLAY), this.store.select(LOCALPLAYINGPRODUCT)),
        map(([action, playing, currentlyPlayingProduct]) => playing && action.stationId === currentlyPlayingProduct ? PauseLocally() : PlayLocally({ stationId: action.stationId }))
      );
    }, { dispatch: true }
  );

  playMusic$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PlaylistLoaded, PlaybackEndedLocally, PlayJingleLocally),
        withLatestFrom(this.store.select(LOCALSHOULDPLAY)),
        map(([action, playing]) => playing),
        filter(playing => playing),
        map(() => PlayNextSongLocally())
      );
    }, { dispatch: true }
  );

  playNextSong$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PlayNextSongLocally),
        withLatestFrom(this.store.select(LOCALPLAYLIST)),
        tap(([action, playlist]) => this.audioService.playNative(playlist))
      );
    }, { dispatch: false }
  );

  reloadPlaylist$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PlayingLocally),
        withLatestFrom(this.store.select(LOCALPLAYLIST), this.store.select(LOCALPLAYINGPRODUCT)),
        filter(([action, playlist, stationId]) => playlist.length < 8),
        concatMap(([action, playlist, stationId]) =>
          this.localPlaybackService.loadPlaylistWithLastSong(stationId, playlist[playlist.length - 1].songId, playlist[playlist.length - 1].startTime)
            .pipe(
              map((addedPlaylist) => {
                const newPlaylist = playlist.concat(addedPlaylist);
                return newPlaylist;
              })
            )
        ),
        tap(playlist => this.audioService.updatePlaylist(playlist)),
        map(playlist => PlaylistReloadedLoaded({ newPlaylist: playlist }))
      );
    }, { dispatch: true }
  );

  stopOnStop$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(PauseLocally, logoutAction),
        tap(songUrl => this.audioService.stop())
      );
    }, { dispatch: false }
  );

  stopOnLogout$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loginStateChanged),
        filter(action => action.loggedIn === false),
        tap(songUrl => this.audioService.stop())
      );
    }, { dispatch: false }
  );

  constructor(private actions$: Actions, private localPlaybackService: LocalPlaybackService, private store: Store<any>, private audioService: AudioService) {
  }

}
