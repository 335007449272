import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MenuController } from '@ionic/angular';
import { MenuStateChanged, MenuStateToggled } from './actions/actions';
import { concatMap, map, withLatestFrom } from 'rxjs/operators';
import { MENUOPEN } from './reducers';
import { of } from 'rxjs';


@Injectable()
export class MenuEffects {

  operateMenu$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(MenuStateChanged),
        concatMap(action => {
            return of(action).pipe(
              withLatestFrom(this.store.select(MENUOPEN))
              ,
              map(([action, menuOpen]) => {
                if (menuOpen) {
                  this.menu.open('menu');
                } else {
                  this.menu.close('menu');
                }
                return action;
              }));
          }
        )
      );
    }
    ,
    { dispatch: false }
  );

  toggleMenu$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(MenuStateToggled),
        concatMap(action => {
            return of(action).pipe(
              withLatestFrom(this.store.select(MENUOPEN)),
              map(([action, menuOpen]) => MenuStateChanged({ open: !menuOpen }))
            );
          }
        )
      );
    }
    ,
    { dispatch: true }
  );

  constructor(private actions$: Actions, private menu: MenuController, private store: Store<any>) {
  }

}
