import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';


import { Observable } from 'rxjs';
import { Output } from './model/cube';
import { select, Store } from '@ngrx/store';
import { AppModules, CURRENT_APP_MODULE, LOGIN, SELECTED_OUTPUT } from './reducers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {


  public selectedOutput$: Observable<Output>;
  public loggedIn: Observable<boolean>;

  public isNotRootRoute$: Observable<boolean>;

  constructor(
    private platform: Platform,
    private store: Store<any>
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      await StatusBar.setStyle({ style: Style.Dark });
      await SplashScreen.hide();
    });
  }

  ngOnInit() {


    this.isNotRootRoute$ = this.store.select(CURRENT_APP_MODULE).pipe(map(module => AppModules.home!=module))
    this.loggedIn = this.store.select(LOGIN)
      .pipe(
        map(loginState => loginState['loggedIn'])
      );
    this.selectedOutput$ = this.store.pipe(select(SELECTED_OUTPUT));
  }

}
