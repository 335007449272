import { WebPlugin } from '@capacitor/core';
import { StreamableSong, ToniqueLocalPlaybackPlugin } from './definitions';

class ToniqueLocalPlaybackWeb extends WebPlugin implements ToniqueLocalPlaybackPlugin {
  constructor() {
    super({
      name: 'ToniqueLocalPlayback',
      platforms: ['web']
    });
    this.audioObj = new Audio();
    this.audioObj.addEventListener('ended', () => {
      this.playlist = this.playlist.splice(1);
      this.doPlay();
    });
  }

  private session = '';

  private audioObj: HTMLAudioElement;

  private playlist: StreamableSong[] = [];

  private intervalId: NodeJS.Timeout | undefined;


  async setSession(options: { session: string }) {
    this.session = options.session;
    return { value: 'OK' };
  }

  async play(playbackOptions: { songs: StreamableSong[] }): Promise<{ value: string; }> {
    console.log('Asked to play songs', playbackOptions);
    this.playlist = playbackOptions.songs;
    this.doPlay();
    this.intervalId = setInterval(()=>{this.updateProgress()}, 500);
    return { value: 'OK' };
  }

  async updatePlaylist(playbackOptions: { songs: StreamableSong[] }): Promise<{ value: string; }> {
    console.log('Asked to updatePlaylist songs', playbackOptions);
    this.playlist = playbackOptions.songs;
    return { value: 'OK' };
  }

  async stop(): Promise<{ value: string; }> {
    this.audioObj.pause();
    // Stop the interval after 10 seconds

  if (!!this.intervalId) {
    clearInterval(this.intervalId);
  }

    return { value: 'OK' };
  }

  async setVolume(options: { volume: number }): Promise<{ value: string; }> {
    console.log('Setting Volume to ', options.volume);
    this.audioObj.volume = options.volume;
    return { value: 'OK' };
  }

  async getVolume(): Promise<{ value: number }> {
    if (!!this.audioObj) {
      return { value: this.audioObj.volume };
    } else {
      return { value: 100.0 };
    }
  }

  doPlay(): void {
    const currentSong = this.playlist[0];

    this.audioObj.src = currentSong.songUrl + '?KMSSESSION=' + this.session;
    this.audioObj.load();

    this.audioObj.play();
    this.notifyListeners('playing', currentSong);
  }

  updateProgress(): void {
    if (!!this.audioObj) {
    const percentage = ((100.0 / this.audioObj.duration) * this.audioObj.currentTime);
    this.notifyListeners('progress',
    {
      progress: percentage,
      duration: this.audioObj.duration,
      currentTime: this.audioObj.currentTime
    } );
    } else {
      console.log('No Audio Object', this.audioObj)
    }
  }

  async getCurrentSong(): Promise<StreamableSong> {
    return this.playlist[0];
  }

  async setCredentials(_options: { username: string, password: string }): Promise<{ value: string }> {
    // Not implemented in web mode
    return { value: 'OK' };
  }
}







export { ToniqueLocalPlaybackWeb };
