
import {ToniqueLocalPlaybackWeb} from './web';

export * from './definitions';

import { registerWebPlugin } from '@capacitor/core';

const toniqueLocalPlaybackPlugin = registerWebPlugin( new ToniqueLocalPlaybackWeb());

export {
  toniqueLocalPlaybackPlugin
}