export interface Product {
  id: string;
  parent_id: string;
  station_id: string;
  type: string;
  name: string;
  description: string;
  displayName: string;
  attributes: string;
  locale: string;
  validity_start: string;
  validity_end: string;
  monthlyPrice: number;
  duration: number;
  coverImages: Images;
  defaultImages: Images;
  smallImages: Images;
}

export interface Images{
  active: string;
  inactive: string;
}

export function productImageUrl(product: Product, active: boolean) {

  /* if (!! product.coverImages){
     return active ? product.coverImages.active : product.coverImages.inactive
   } else */
   if (!! product.defaultImages) {
     return active ? product.defaultImages.active : product.defaultImages.inactive
   } else {
     return "assets/images/stationbg1.png";
   }
 }
