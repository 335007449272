import { createAction, props } from '@ngrx/store';
import { Cube } from '../model/cube';
import { Product } from '../model/product';
import { LocalPlayableSong } from '../model/local-playback';
import { Jingle } from '../model/jingle';
import { AppModules } from '../reducers';
import { StreamingPlaybackProgress } from 'tonique-local-playback';

export const login = createAction(
  '[Login Page] Login',
  props<{ username: string; password: string }>()
);

export const loginStateChanged = createAction(
  '[Login] State Changed',
  props<{ loggedIn: boolean }>()
);

export const logoutAction = createAction(
  '[Login] Logout'
);

export const CubesLoaded = createAction(
  '[CUBE] loaded',
  props<{ cubes: Cube[] }>()
);

export const CubeSelected = createAction(
  '[CUBE] cube selected',
  props<{ selectedCubeId: string}>()
);
export const OutputSelected = createAction(
  '[CUBE] output selected',
  props<{ selectedCubeId: string, selectedOutputIndex: number }>()
);

export const CubesStatusUpdated = createAction(
  '[CUBE] status updated',
  props<{ cubes: Cube[] }>()
);

export const LikedOnCube = createAction(
  '[CUBE] Like',
  props<{ stationId: string, songId: string, liked: boolean, disliked: boolean }>()
);

export const ProductsLoaded = createAction(
  '[PRODUCT] loaded',
  props<{ subscribedProducts: Product[], jingles: Jingle[] }>()
);

export const ModuleActicated = createAction(
  '[APP] Module Activated ',
  props<{ module: AppModules }>()
);

export const MenuStateChanged = createAction(
  '[APP] MENU State Changed',
  props<{ open: boolean }>()
);

export const MenuStateToggled = createAction(
  '[APP] MENU State toggled'
);

export const ShowProductDetails = createAction(
  '[APP] Show Product',
  props<{ productOrJingle: Product }>()
);

export const HideProductDetails = createAction(
  '[APP] Hide Product'
);

export const TogglePlayLocally = createAction(
  '[LOCALPLAY] TogglePlay',
  props<{ stationId: string }>()
);

export const PlayJingleLocally = createAction(
  '[LOCALPLAY] PlayJingleLocally',
  props<{ jingle: Jingle }>()
);

export const PlayLocally = createAction(
  '[LOCALPLAY] Play',
  props<{ stationId: string }>()
);

export const PlaylistLoaded = createAction(
  '[LOCALPLAY] Playlist Loaded',
  props<{ playlist: LocalPlayableSong[] }>()
);

export const PlaylistReloadedLoaded = createAction(
  '[LOCALPLAY] Playlist Re-Loaded',
  props<{ newPlaylist: LocalPlayableSong[] }>()
);

export const PauseLocally = createAction(
  '[LOCALPLAY] Pause'
);

export const PlaybackStartedLocally = createAction(
  '[LOCALPLAY] Playback Started'
);
export const PlaybackEndedLocally = createAction(
  '[LOCALPLAY] Playback Ended'
);

export const PlayNextSongLocally = createAction(
  '[LOCALPLAY] PlayNextSongLocally'
);

export const PlayingLocally = createAction(
  '[LOCALPLAY] PlayingLocally',
  props<{ songPersistentId: string }>()
);

export const ProgressLocal = createAction(
  '[LOCALPLAY] ProgressLocal',
  props<{ newProgress: StreamingPlaybackProgress }>()
);


export const RemoveFirstSong = createAction(
  '[LOCALPLAY] RemoveFirstSong'
);

export const SetVolumeLocally = createAction(
  '[LOCALPLAY] Set Volume',
  props<{ newVolume: number }>()
);

export const LikedLocally = createAction(
  '[LOCALPLAY] Like',
  props<{ stationId: string, songId: string, liked: boolean, disliked: boolean }>()
);
