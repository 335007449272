// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.login{
  background-color: #FFFFFF;
  height: 100vh;
  .form {
    padding-bottom: 79px;
    height:100vh;
  }
}

.login-form-control{
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1.5px solid #003760;

  font-family: Agenda;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;

  width: 100%;

  color: #CCCCCC;
}

.logo{
  width: 100%;
  background-image: url("../../assets/tonique-login.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 250px;
  height: 157px;
  margin-bottom: 50px;
  @media screen and ( max-height: 400px ){
    height: 80px;
    margin-bottom: 30px;
  }
}



.username-icon{
  background-image: url("../../assets/mail.svg");
}
.password-icon{
  background-image: url("../../assets/lock.svg");
}
.icon{
  display: inline-block;
  width: 28px;
  margin-right: 18px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
}

.loginbutton{
  width: 100%;
  height: 79px;

  background: #000000;

  font-family: Agenda;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 52px;

  text-align: center;
  letter-spacing: 0.05em;

  color: #FFFFFF;
}
.footer{
  position: absolute;
bottom: 0;
  width: 100vw;
  height: 79px;
}
*/`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CAAA","sourcesContent":["/*.login{\n  background-color: #FFFFFF;\n  height: 100vh;\n  .form {\n    padding-bottom: 79px;\n    height:100vh;\n  }\n}\n\n.login-form-control{\n  border-top: 0;\n  border-left: 0;\n  border-right: 0;\n  border-bottom: 1.5px solid #003760;\n\n  font-family: Agenda;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 19px;\n  display: flex;\n  align-items: center;\n  letter-spacing: 0.05em;\n\n  width: 100%;\n\n  color: #CCCCCC;\n}\n\n.logo{\n  width: 100%;\n  background-image: url(\"../../assets/tonique-login.svg\");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  max-width: 250px;\n  height: 157px;\n  margin-bottom: 50px;\n  @media screen and ( max-height: 400px ){\n    height: 80px;\n    margin-bottom: 30px;\n  }\n}\n\n\n\n.username-icon{\n  background-image: url(\"../../assets/mail.svg\");\n}\n.password-icon{\n  background-image: url(\"../../assets/lock.svg\");\n}\n.icon{\n  display: inline-block;\n  width: 28px;\n  margin-right: 18px;\n\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  height: 20px;\n}\n\n.loginbutton{\n  width: 100%;\n  height: 79px;\n\n  background: #000000;\n\n  font-family: Agenda;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 26px;\n  line-height: 52px;\n\n  text-align: center;\n  letter-spacing: 0.05em;\n\n  color: #FFFFFF;\n}\n.footer{\n  position: absolute;\nbottom: 0;\n  width: 100vw;\n  height: 79px;\n}\n*/"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
