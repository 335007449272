import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { environment } from '../environments/environment';
import { ApiHttpInterceptor } from './util/HttpInterceptor';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MenuEffects } from './menu.effects';
import { LocalPlaybackEffects } from './local-playback.effects';
import { SelectModeComponent } from './select-mode/select-mode.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AuthenticationEffects } from './authentication.effects';
import { NgxSliderModule } from 'ngx-slider-v2';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, LoginComponent, SelectModeComponent, FeedbackComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2/dist/sweetalert2.js').then(({ default: swal }) => swal.mixin({

      }))
    }),
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSliderModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([MenuEffects, LocalPlaybackEffects, AuthenticationEffects]
    )
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'BASE_API_URL', useValue: environment.apiUrl },
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true
    }
    ]
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
