import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    error = false;


    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged i
        // if (this.authenticationService.currentUserValue) {
        //     this.router.navigate(['/']);
        //  }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.authenticationService.tryAutologin();

    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.login(this.f.username.value.trim(), this.f.password.value.trim());
    }

    login(username: string, password: string) {
        if (!!username && !!username) {
            const login = this.authenticationService.login(username, password);
            login.pipe(
                first()
            )
                .subscribe(
                    data => {
                        this.loading = false;
                        this.error = false;
                    },
                    error => {
                        console.log("Error logging in", error);
                        this.loading = false;
                        this.error = true;
                    });
        }
    }
}
