import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Output, Song } from '../../model/cube';
import { Store } from '@ngrx/store';
import { CubeService } from '../../cube.service';
import { filter, map} from 'rxjs/operators';
import { Product, productImageUrl } from '../../model/product';
import { Toast } from 'src/app/util/toast';
import { FeedbackService } from 'src/app/feedback.service';
import { HideProductDetails, LikedLocally } from 'src/app/actions/actions';
import { LOCALPASTPLAYLIST } from 'src/app/reducers';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  @Input()
  public product: Product;

  @Input()
  public output: Output;
  
  @Input()
  public playing: boolean = false;

  

  constructor(private store: Store<any>, private cubeService: CubeService, private feedbackService : FeedbackService) {
  }

  public getSongs() : Observable<Song[]>{
    if (this.playing === true && !! this.output){
      return of ([...this.output.pastPlayedSongs].reverse());
    } 
    if (this.playing === true && !!! this.output) {
      return this.store.select(LOCALPASTPLAYLIST)
      .pipe(
        map(songs => [...songs].reverse())
      );
    }
    else {
      return of([]);
    }
  }

  public getCurrentSong(): Observable<Song>{
   return this.getSongs()
    .pipe(
      filter(songs => songs.length>0),
      map(songs => songs[0])
    )
  }

  ngOnInit() {

  }

  productImageUrl = productImageUrl;

  like(song: Song) {
    if (!!song) {
      Toast.fire({
        title: `You liked ${song.name}`
      });
       
       this.feedbackService.likeSong(this.product.id, song.persistentId, '')
            .pipe(
              map(_ => LikedLocally({ stationId: this.product.id, songId: song.persistentId, liked: true, disliked: false }))
            )
        .subscribe(action => this.store.dispatch(action));
    }
  }

  dislike(song: Song) {
    if (!!song) {
      Toast.fire({
        title: `You do not like ${song.name}`
      });
       
       this.feedbackService.dislikeSong(this.product.id, song.persistentId, '')
            .pipe(
              map(_ => LikedLocally({ stationId: this.product.id, songId: song.persistentId, liked: false, disliked: true }))
            )
        .subscribe(action => this.store.dispatch(action));
    }
  }

  isCurrentyPlayingSong(song: Song): Observable<boolean>{
    return this.getCurrentSong().pipe(
      map(currentSong => currentSong.persistentId == song.persistentId)
    );
  }
  close(){
    this.store.dispatch(HideProductDetails()); 
  }

}
