import { ActionReducerMap, createReducer, createSelector, MetaReducer, on } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { Cube, Output, Song } from '../model/cube';
import {
  CubeSelected,
  CubesLoaded,
  CubesStatusUpdated,
  HideProductDetails,
  LikedLocally,
  LikedOnCube,
  loginStateChanged,
  logoutAction,
  MenuStateChanged, ModuleActicated,
  OutputSelected,
  PauseLocally,
  PlayingLocally,
  PlayJingleLocally,
  PlaylistLoaded,
  PlaylistReloadedLoaded,
  PlayLocally,
  ProductsLoaded,
  ProgressLocal,
  SetVolumeLocally,
  ShowProductDetails
} from '../actions/actions';
import { Product } from '../model/product';
import { LocalPlayableSong } from '../model/local-playback';
import { Jingle } from '../model/jingle';
import { StreamingPlaybackProgress } from 'tonique-local-playback';

export const LOGIN = 'login';

export const enum AppModules { home, cubecontrol, localPlayback };

export interface State {
  cubes: CubeState;
  login: LoginState;
  products: ProductState;
  app: AppState;
  localPlayback: LocalPlaybackState;
}

export interface LoginState {
  loggedIn: boolean;
}

export interface CubeState {
  cubes: Cube[];
  selectedCubeId?: string;
  selectedOutputIndex: number;
  lastLike: { stationId: string, songId: string, liked: boolean, disliked: boolean };
}

export interface AppState {
  menuOpen: boolean;
  module: AppModules;
  detailsShowOfProduct: Product;
}

export interface LocalPlaybackState {
  playing: boolean;
  currentStationId?: string;
  playlist: LocalPlayableSong[];
  pastPlayedSongs: LocalPlayableSong[];
  volume: number;
  progress: StreamingPlaybackProgress;
}

export const STATE = (state: State) => state;

export const CUBES = createSelector(STATE, (state: State) => state.cubes);

export const PRODUCTSTATE = (state: State) => state.products;

export const APPSTATE = (state: State) => state.app;

export const LOCALPLAYBACKSTATE = (state: State) => state.localPlayback;

export const LOCALPLAYINGPRODUCT = createSelector(LOCALPLAYBACKSTATE, (productState) => productState.currentStationId);

export const LOCALPLAYLIST = createSelector(LOCALPLAYBACKSTATE, (productState) => productState.playlist);

export const LOCALPASTPLAYLIST = createSelector(LOCALPLAYBACKSTATE, (productState) => productState.pastPlayedSongs);

/*
export const LOCALCURRENTSONG = createSelector(LOCALPLAYBACKSTATE, (productState) => (productState.pastPlayedSongs == null || productState.pastPlayedSongs.length===0) 
  ? null 
  : productState.pastPlayedSongs[productState.pastPlayedSongs.length-1];
  */

export const LOCALSHOULDPLAY = createSelector(LOCALPLAYBACKSTATE, (productState) => productState.playing);

export const LOCALVOLUME = createSelector(LOCALPLAYBACKSTATE, (productState) => productState.volume);

export const LOCALPROGRESS = createSelector(LOCALPLAYBACKSTATE, (productState) => productState.progress);

export const MENUOPEN =
  createSelector(APPSTATE, (appState) => appState.menuOpen);

export const SUBSCRIBEDPRODUCTS =
  createSelector(PRODUCTSTATE, (productState) => productState.subscribedProducts);

export const USER_HAS_CUBES =
  createSelector(CUBES, (cubeState: CubeState) => (!!cubeState.cubes && cubeState.cubes.length > 0));

export const ALL_CUBES =
  createSelector(CUBES, (cubeState: CubeState) => cubeState.cubes);
export const SELECTED_CUBE =
  createSelector(CUBES, (cubeState: CubeState) => cubeState.cubes.find(cube => cube.id === cubeState.selectedCubeId));
export const SELECTED_OUTPUT =
  createSelector(CUBES, SELECTED_CUBE, (cubeState, selectedCube) => (!!selectedCube) ? selectedCube.outputs[cubeState.selectedOutputIndex - 1] : undefined as Output);
export const LAST_LIKED =
  createSelector(CUBES, (cubeState: CubeState) => cubeState.lastLike);
export const CURRENT_SONG =
  createSelector(CUBES, SELECTED_OUTPUT, LAST_LIKED, (cubeState, selectedOutput, lastLike) => {
    const song = (!!selectedOutput) ? selectedOutput.song : {} as Song;
    if (!!song && lastLike.songId === song.persistentId) {
      return { ...song, liked: lastLike.liked, disliked: lastLike.disliked };
    }
    return song;
  });



export const CURRENT_APP_MODULE = createSelector(APPSTATE, (appState) => appState.module);

export const APP_PRODUCT_DETAILS_SHOWN = createSelector(APPSTATE, (appState) => appState.detailsShowOfProduct);


export interface ProductState {
  subscribedProducts: Product[];
  jingles: Jingle[];
}

export const initialCubeState: CubeState = { cubes: [], selectedOutputIndex: 0, lastLike: { stationId: 'none', songId: 'none', liked: false, disliked: false } };
export const initialLoginState: LoginState = { loggedIn: false };
export const initialProductState: ProductState = { subscribedProducts: [], jingles: [] };
export const initialAppState: AppState = { menuOpen: false, module: AppModules.home, detailsShowOfProduct: undefined };
export const initialLocalPlaybackState: LocalPlaybackState = { playing: false, playlist: [], pastPlayedSongs: [], volume: undefined , progress: {progress: 0.0, duration: 1, currentTime: 0}};

export const initialState: State = {
  cubes: initialCubeState,
  login: initialLoginState,
  products: initialProductState,
  app: initialAppState,
  localPlayback: initialLocalPlaybackState
};
export const productReducer = createReducer<ProductState>(initialProductState,
  on(ProductsLoaded,
    (state, { subscribedProducts, jingles }) => ({
      ...state, subscribedProducts, jingles
    })
  )
);


export const loginReducer = createReducer<LoginState>(initialLoginState,
  on(loginStateChanged,
    (state, { loggedIn }) => ({
      ...state, loggedIn
    })
  ),
  on(logoutAction, (state) =>
  ({
    ...initialLoginState
  })
  )
);

export const cubeReducer = createReducer<CubeState>(initialCubeState,
  on(logoutAction, (state) => ({
    ...initialCubeState
  })),
  on(CubesLoaded,
    (state, { cubes }) => ({
      ...state, cubes, selectedCubeId: cubes.length > 0 ? cubes[0].id : undefined, selectedOutputIndex: 1
    })
  ),
  on(CubeSelected,
    (state, { selectedCubeId }) => {
      if (state.selectedCubeId === selectedCubeId) return state;
      return {
        ...state, selectedCubeId, selectedOutputIndex: 1
      };
    }
  ),
  on(OutputSelected,
    (state, { selectedCubeId, selectedOutputIndex }) => ({
      ...state, selectedCubeId, selectedOutputIndex
    })
  ),
  on(CubesStatusUpdated,
    (state, updatedCubes) => {
      const cubes = state.cubes.map(
        (cube, index) => {
          const newCube = Object.assign({}, cube);
          const updatedCube = updatedCubes.cubes.find(cube => cube.id === newCube.id);
          newCube.outputs = cube.outputs.map((output, i) => {
            const updatedOutput = updatedCube.outputs[i];
            const resultingOutput = Object.assign({}, output, updatedOutput);
            return resultingOutput;
          });
          newCube.online = updatedCube.online;
          return newCube;
        }
      );
      return {
        ...state, cubes
      };
    }),
  on(LikedOnCube, (state, { stationId, songId, liked, disliked }) => (
    {
      ...state,
      lastLike: { ...state.lastLike, stationId, songId, liked, disliked }
    }
  ))
);

export const appStateReducer = createReducer<AppState>(initialAppState,
  on(MenuStateChanged,
    (state, { open }) => ({
      ...state, menuOpen: open
    })
  ),
  on(ModuleActicated, (state, { module }) => ({
    ...state, module: module
  })
  ),
  on(ShowProductDetails,
    (state, { productOrJingle }) => ({
      ...state, detailsShowOfProduct: productOrJingle
    })),
  on(HideProductDetails, (state, { }) => ({
    ...state, detailsShowOfProduct: undefined
  })),
);

export const localPlaybackReducer = createReducer<LocalPlaybackState>(initialLocalPlaybackState,
  on(logoutAction, (state) => ({
    ...initialLocalPlaybackState
  })),
  on(PlayLocally,
    (state, { stationId }) => (
      { ...state, playing: true, currentStationId: stationId }
    )),
  on(PauseLocally, (state, a) => (
    { ...state, playing: false })
  ),
  on(PlaylistLoaded, (state, { playlist }) => (
    { ...state, playlist, pastPlayedSongs: [] }
  )),
  on(PlaylistReloadedLoaded, (state, { newPlaylist }) => {
    return { ...state, playlist: newPlaylist };
  }
  ),
  on(PlayJingleLocally, (state, { jingle }) => {
    const jingleSong = {
      persistentId: jingle.songPersistentId,
      name: jingle.displayName,
      artist: jingle.artist,
      hasCover: false,
      songUrl: jingle.songUrl,
      songId: jingle.songPersistentId, // do we need to the ID?
      liked: false,
      disliked: false
    } as LocalPlayableSong;
    const newPlaylist = [...state.playlist];
    newPlaylist.unshift(jingleSong);
    return { ...state, playlist: newPlaylist };
  }),
  on(PlayingLocally, (state, { songPersistentId }) => {
    if (state.pastPlayedSongs.length === 0 || state.pastPlayedSongs[(state.pastPlayedSongs.length - 1)].persistentId != songPersistentId) {
      const songIndex = state.playlist.findIndex(song => song.persistentId === songPersistentId);
      if (songIndex < 0) {
        console.error('Played song, we did not have');
        return state;
      }
      const song = {... state.playlist[songIndex] };
      console.log('Found Song', songPersistentId, song);
      song.playTime = new Date().toISOString();
      return {
        ...state,
        pastPlayedSongs: state.pastPlayedSongs.concat(song).slice(Math.max(state.pastPlayedSongs.length - 19, 0)), // Show 20 Songs
        playlist: [...state.playlist].splice(songIndex)
      };
    } else {
      return state;
    }
  }
  ),
  on(ProgressLocal, (state, {newProgress}) => (
    {
      ...state,
      progress: newProgress
    }
    )
  ),
  on(SetVolumeLocally, (state, { newVolume }) => (
    {
      ...state,
      volume: newVolume
    }
  )
  ),
  on(LikedLocally, (state, { stationId, songId, liked, disliked }) => (
    {
      ...state,
      pastPlayedSongs: state.pastPlayedSongs.map(song => song.persistentId === songId ? Object.assign({}, song, { liked, disliked }) : song)
    }
  )
  )
);

export const reducers: ActionReducerMap<State> = {
  cubes: cubeReducer,
  login: loginReducer,
  products: productReducer,
  app: appStateReducer,
  localPlayback: localPlaybackReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
