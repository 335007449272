import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {  Store } from '@ngrx/store';
import { ALL_CUBES, CUBES, LOGIN, USER_HAS_CUBES } from '../reducers';
import { map } from 'rxjs/operators';
import { Cube } from '../model/cube';

@Component({
  selector: 'app-select-mode',
  templateUrl: './select-mode.component.html',
  styleUrls: ['./select-mode.component.scss']
})
export class SelectModeComponent implements OnInit {

  public loggedIn: Observable<boolean>;
  public hasCubes$: Observable<boolean>;
  public cubes$: Observable<Cube[]>;

  constructor(private store: Store<any>) { }

  ngOnInit(): void {
    this.loggedIn = this.store.select(LOGIN)
      .pipe(
        map(loginState => loginState['loggedIn'])
      );
    this.hasCubes$ = this.store.select(USER_HAS_CUBES);
    this.cubes$ = this.store.select(ALL_CUBES);

  }
}
