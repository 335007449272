import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { SelectModeComponent } from './select-mode/select-mode.component';
import { FeedbackComponent } from './feedback/feedback.component';

import { Store } from '@ngrx/store';
import { ModuleActicated } from './actions/actions';
import { AppModules } from './reducers';

const updateActiveModel: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    inject(Store).dispatch(ModuleActicated({ module: AppModules.home }));
    return true;
  };

const routes: Routes = [
  {
    path: 'select-mode',
    component: SelectModeComponent,
    canActivate: [updateActiveModel]
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [updateActiveModel]
  },
  {
    path: 'cube-control',
    loadChildren: () => import('./cube-control/cube-control.module').then(m => m.CubeControlModule)
  },
  {
    path: 'listen-on-device',
    loadChildren: () => import('./listen-on-device/listen-on-device.module').then(m => m.ListenOnDeviceModule)
  },
  { path: '**', redirectTo: '/select-mode' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, bindToComponentInputs: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
