import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeContext, Options } from 'ngx-slider-v2';

@Component({
  selector: 'app-volume-widget',
  templateUrl: './volume-widget.component.html',
  styleUrls: ['./volume-widget.component.scss']
})
export class VolumeWidgetComponent {

  public opened = false;

  @Input("value")
  public volume = 50;

  @Output("userChangeEnd")
  userChangeEnd = new EventEmitter<number>;

  options: Options = {
    floor: 0,
    ceil: 100,
    hideLimitLabels: true,
    showTicks: false,
    translate: (value: number): string => {
      return'';// not text value + '%';
    },
    vertical: true,
    //selectionBarGradient:{from:'#000', to: '#000'}
  };

  toggle(){
    this.opened = !this.opened;
  }

  setVolume(){
    console.log('Emitting Volume Change', this.volume)
    this.userChangeEnd.emit(this.volume);
    this.opened = false;
  }
}
