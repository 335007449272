// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  background-color: #FFFFFF;
  height: 100vh;
}

.logo {
  width: 100%;
  background-image: url('tonique-login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 250px;
  height: 157px;
  margin-bottom: 50px;
}
@media screen and (max-height: 400px) {
  .logo {
    height: 80px;
    margin-bottom: 30px;
  }
}

.left {
  width: 100%;
  height: 79px;
  background: #FFFFFF;
  display: block;
  font-family: Agenda;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  /* identical to box height, or 200% */
  text-align: center;
  letter-spacing: 0.05em;
  color: #003760;
}

.right {
  width: 100%;
  height: 79px;
  background: #000000;
  display: block;
  font-family: Agenda;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.footer {
  position: absolute;
  bottom: 0;
}
.footer button {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/select-mode/select-mode.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;AACF;;AAGA;EACE,WAAA;EACA,0CAAA;EACA,2BAAA;EACA,4BAAA;EACA,wBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAAF;AACE;EATF;IAUI,YAAA;IACA,mBAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,YAAA;EAEA,mBAAA;EACA,cAAA;EAEA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,qCAAA;EACA,kBAAA;EACA,sBAAA;EAEA,cAAA;AADF;;AAKA;EACE,WAAA;EACA,YAAA;EAEA,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EAEA,kBAAA;EACA,sBAAA;EAEA,cAAA;AALF;;AAOA;EACE,kBAAA;EACA,SAAA;AAJF;AAKE;EACE,UAAA;AAHJ","sourcesContent":[".logo-container {\n  background-color: #FFFFFF;\n  height: 100vh;\n\n}\n\n.logo {\n  width: 100%;\n  background-image: url(\"../../assets/tonique-login.svg\");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  max-width: 250px;\n  height: 157px;\n  margin-bottom: 50px;\n  @media screen and (max-height: 400px) {\n    height: 80px;\n    margin-bottom: 30px;\n  }\n}\n\n.left{\n  width: 100%;\n  height: 79px;\n\n  background: #FFFFFF;\n  display: block;\n\n  font-family: Agenda;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 26px;\n  /* identical to box height, or 200% */\n  text-align: center;\n  letter-spacing: 0.05em;\n\n  color: #003760;\n;\n}\n\n.right{\n  width: 100%;\n  height: 79px;\n\n  background: #000000;\n  display: block;\n  font-family: Agenda;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 26px;\n\n  text-align: center;\n  letter-spacing: 0.05em;\n\n  color: #FFFFFF;\n}\n.footer{\n  position: absolute;\n  bottom: 0;\n  button{\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
