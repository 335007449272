import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { PlayingLocally, ProgressLocal, SetVolumeLocally } from '../actions/actions';
import { AuthenticationService } from '../authentication.service';
import { LOCALVOLUME } from '../reducers';

import 'tonique-local-playback';
import { Plugins } from '@capacitor/core';
import { LocalPlayableSong } from '../model/local-playback';
import { StreamableSong } from 'tonique-local-playback/dist/esm/definitions';
import { coverartUrl } from './song-coverart.pipe';
import { debounceTime, filter } from 'rxjs/operators';
import { StreamingPlaybackProgress } from 'tonique-local-playback';

const { ToniqueLocalPlayback } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AudioService {


  constructor(
    private platform: Platform,
    private store: Store<any>,
    private authenticationService: AuthenticationService
  ) {

    ToniqueLocalPlayback.addListener('playing', (song: StreamableSong) => {
      console.log('playing was fired', song);
      this.store.dispatch(PlayingLocally({ songPersistentId: song.persistentId }));
    });

    ToniqueLocalPlayback.addListener('progress', (progress: StreamingPlaybackProgress) => {
      //console.log('progess received', progress);
      this.store.dispatch(ProgressLocal({ newProgress: progress }));
    });

    ToniqueLocalPlayback.getVolume()
      .then(result => this.store.dispatch(SetVolumeLocally({ newVolume: result.value }))
        , _ => this.store.dispatch(SetVolumeLocally({ newVolume: 1.0 }))
      );

    this.store.select(LOCALVOLUME)
      .pipe(
        debounceTime(300),
        filter(volume => !!volume)
      )
      .subscribe(volume => {
        ToniqueLocalPlayback.setVolume({ volume });
      });

  }


  stop() {
    ToniqueLocalPlayback.stop();
  }


  private toStreamableSongs(songs: LocalPlayableSong[]): StreamableSong[] {
    return songs.map(song => {
      return {
        persistentId: song.persistentId,
        name: song.name,
        artist: song.artist,
        hasCover: song.hasCover,
        coverUrl: coverartUrl(song),
        startTime: song.startTime,
        songUrl: song.songUrl,
        songId: song.songId
      } as StreamableSong;

    });
  }

  public updatePlaylist(songs: LocalPlayableSong[]) {
    ToniqueLocalPlayback.updatePlaylist({
      songs: this.toStreamableSongs(songs)
    });
  }

  public pollSong(): Promise<StreamableSong> {
    return ToniqueLocalPlayback.getCurrentSong();
  }

  public playNative(songs: LocalPlayableSong[]) {
    this.authenticationService.getSessionId()
        .subscribe(sessionId => {
          ToniqueLocalPlayback.setSession({ session: sessionId });
          ToniqueLocalPlayback.setCredentials({ username: this.authenticationService.getUsername(), password: this.authenticationService.getPassword() });
          ToniqueLocalPlayback.play({
            songs: this.toStreamableSongs(songs)
          });
        });

  }

  public play(productId: string) {
    this.authenticationService.getSessionId()
        .subscribe(sessionId => {
            ToniqueLocalPlayback.setSession({ session: sessionId });
            ToniqueLocalPlayback.setCredentials({ username: this.authenticationService.getUsername(), password: this.authenticationService.getPassword() });
            ToniqueLocalPlayback.play({
              productId
            });
        });
  }

}
