import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import { forkJoin, from, mergeMap, Observable, of } from 'rxjs';
import {filter, flatMap, map, tap} from 'rxjs/operators';
import {AuthResponse} from './model/authresponse';
import {Store} from '@ngrx/store';
import {CubesLoaded, loginStateChanged, logoutAction, ProductsLoaded} from './actions/actions';
import {get, remove, set} from './util/storage.service';
import {CubeService} from './cube.service';
import {Platform} from '@ionic/angular';

const keyUsername = 'tnqu';
const keyPassword = 'tnqp';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private cookieUpdateDate = -1;
    private cookie = '';
    username = '';
    password = '';


    constructor(
        private http: HttpClient,
        private store: Store<any>,
        private router: Router,
        private cubeService: CubeService,
        private platform: Platform) {
        this.platform.resume.pipe(
            flatMap(_ => this.getCookie())
        ).subscribe((_) => {
            // resumed
        });
    }

    getSessionId() {
        return this.getCookie()
            .pipe(
                map(cookie => cookie.substring(cookie.indexOf('=') + 1))
            );
    }

    tryAutologin() {
        forkJoin(
            [
                from(get(keyUsername)),
                from(get(keyPassword))
            ]
        )
            .pipe(
                filter(([username, password]) => !!username && !!password),
                flatMap(([username, password]) => this.login(username, password, true))
            )
            .subscribe(result => console.log(result));
    }

    public login(username: string, password: string, updateAppState = true): Observable<string> {
        console.log('Logging in', username, !!password ? '<password>' : 'no password');
        const url = 'login';

        return this.http.post<AuthResponse>(url,
            {username, password}
        )
            .pipe(
                tap(response => {
                    this.cookie = response.auth;
                    this.cookieUpdateDate = Date.now() + 15 * 60 * 1000;
                }),
                tap(response => {
                    if (updateAppState) {
                        this.store.dispatch(loginStateChanged({loggedIn: true}));
                        this.store.dispatch(CubesLoaded({cubes: response.cubes}));
                        this.store.dispatch(ProductsLoaded({
                            subscribedProducts: response.subscribedProducts,
                            jingles: response.jingles
                        }));
                    }
                }),
                mergeMap(result => from(set(keyUsername, username))),
                mergeMap(result => from(set(keyPassword, password))),
                tap(_ => {
                    this.username = username;
                    this.password = password;
                }),
                map(input => 'success')
            );
    }

    async logout() {
        this.cookie = '';
        this.cookieUpdateDate = -1;
        this.username = '';
        this.password = '';
        this.cubeService.stopPollingStatus();
        this.store.dispatch(logoutAction());
        await remove(keyUsername);
        await remove(keyPassword);
        await this.router.navigate([]);

    }

    getCookie(): Observable<string> {
        if (this.cookieUpdateDate < 0){
            // Not logged in
            return of(this.cookie);
        }
        else if (this.cookieUpdateDate < Date.now()) {
            return this.login(this.username, this.password, false)
                .pipe(
                    map(_ => this.cookie)
                );
        }
        return of(this.cookie);
    }

    getUsername() {
        return this.username;
    }

    getPassword() {
        return this.password;
    }
}
