import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  public feedbackText: string;

  sentSuccess = false;

  constructor(private feedbackService: FeedbackService) {
  }

  ngOnInit(): void {
  }

  sendFeedback() {
    this.feedbackService.generalFeedback(this.feedbackText).subscribe(() => {
      console.log('Send Feedback');
      this.sentSuccess = true;
    });
  }

}
