import { Injectable } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MenuController } from '@ionic/angular';
import { loginStateChanged, MenuStateChanged, MenuStateToggled, PauseLocally } from './actions/actions';
import { concatMap, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { MENUOPEN } from './reducers';
import { of } from 'rxjs';
import { CubeService } from './cube.service';
import { AuthenticationService } from './authentication.service';


@Injectable()
export class AuthenticationEffects {

  autoRefreshCubeStatus$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loginStateChanged),
        filter(action => action.loggedIn),
        tap(() => this.cubeService.startPollingStatus())
      );
    }
    ,
    { dispatch: false }
  );

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      tap(() => this.authenticationService.tryAutologin()),
      take(1)
    );
  }
  ,
  { dispatch: false }
);



  stopOnLogout$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loginStateChanged),
        filter(action => action.loggedIn == false),
        map(() => PauseLocally())
      );
    }
    ,
    { dispatch: true }
  );



  constructor(private actions$: Actions, 
    private cubeService: CubeService, 
    private authenticationService: AuthenticationService, 
    private store: Store<any>) {
  }

}
