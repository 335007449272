import { Pipe, PipeTransform } from '@angular/core';
import { LocalPlayableSong } from '../model/local-playback';

@Pipe({
  name: 'songCoverart'
})
export class SongCoverartPipe implements PipeTransform {

  transform(value: LocalPlayableSong, ...args: unknown[]): string {
    return coverartUrl(value);
  }

}

export function coverartUrl(value: LocalPlayableSong): string {
  if (!!value.persistentId && value.hasCover) {
    // Load the cover
    return `https://cube.tonique.com/gateway/gateway.php?url=https%3A%2F%2Fha.tonique.com&mode=cover&persistent_id=${value.persistentId}`;
  } else {
    return '/assets/default-cover.svg';
  }
}
