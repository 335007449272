import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PlayerHeaderComponent } from './player-header/player-header.component';
import { IonicModule } from '@ionic/angular';
import { ImprintComponent } from './imprint/imprint.component';
import { NothingComponent } from './nothing/nothing.component';
import { VolumeWidgetComponent } from './volume-widget/volume-widget.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ProductDetailsComponent } from './product-details/product-details.component';
import {DateAgoPipe} from '../util/TimeAgoPipe';
@NgModule({
  declarations: [ PlayerHeaderComponent, ImprintComponent, NothingComponent, VolumeWidgetComponent, ProductDetailsComponent, DateAgoPipe],
  imports: [
    CommonModule,
    SweetAlert2Module,
    RouterModule,
    IonicModule,
    NgxSliderModule
  ],
  exports:[PlayerHeaderComponent, ImprintComponent, NothingComponent, VolumeWidgetComponent, ProductDetailsComponent, DateAgoPipe]
})
export class SharedModule { }
