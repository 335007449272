// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.header {
  –padding-top: var(–ion-safe-area-top, 0);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/player-header/player-header.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,wCAAA;AAEF","sourcesContent":[".header{\n  –padding-top: var(–ion-safe-area-top, 0);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
